.App {
  display: flex;
  flex-direction: column;
}
.app_head {
  color: #04aa6d;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.app_image {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
}

.app_desc {
  width: 100%;
  max-width: 500px;
}

.app_form {
  max-width: 500px;
  width: 100%;
}

input {
  display: block;
  padding: 12px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 6px;
  margin-bottom: 16px;
  resize: vertical;
}

.submit {
  background-color: #04aa6d;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submit:hover {
  background-color: #45a049;
}

.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}
